import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/style.scss';

// plugin
import React, { useState } from 'react';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// page
import AboutCOIAS from './pages/AboutCOIAS';

import Contact from './pages/Contact';

import DataAnalysis from './pages/DataAnalysis';
import Candidates from './pages/data/Candidates';
import Provisional from './pages/data/Provisional';
import Numbered from './pages/data/Numbered';

import Dictionary from './pages/details/Dictionary';

import Policy from './pages/Policy';

import Procedure from './pages/details/Procedure';

import FAQ from './pages/details/FAQ';

import Guide from './pages/Guide';

import LogInPage from './pages/LogInPage';

import News from './pages/News';

import Regulation from './pages/Regulation';

import FixSendMpc2023Nov from './pages/special/FixSendMpc2023Nov';

import Issue2023Nov from './pages/special/Issue2023Nov';

import TopPage from './pages/TopPage';

// Modal
import ImportantNoticeModal from './components/Modal/ImportantNoticeModal';

import './i18n.js';

const HAVE_IMPORTANT_NOTICE = false;

const App = () => {
  const [importantNoticeModalShow, setImportantNoticeModalShow] = useState(
    HAVE_IMPORTANT_NOTICE,
  );
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<TopPage />} />
          <Route exact path="/about_coias" element={<AboutCOIAS />} />
          <Route exact path="/news" element={<News />} />
          <Route exact path="/guide" element={<Guide />} />
          <Route exact path="/data_analysis" element={<DataAnalysis />} />
          <Route
            exact
            path="/data_analysis/candidates"
            element={<Candidates />}
          />
          <Route
            exact
            path="/data_analysis/provisional"
            element={<Provisional />}
          />
          <Route exact path="/data_analysis/numbered" element={<Numbered />} />
          <Route exact path="/details/procedure" element={<Procedure />} />
          <Route exact path="/details/dictionary" element={<Dictionary />} />
          <Route exact path="/details/faq" element={<FAQ />} />
          <Route
            exact
            path="/special/fix-send-mpc-2023nov"
            element={<FixSendMpc2023Nov />}
          />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/login" element={<LogInPage />} />
          <Route exact path="/regulation" element={<Regulation />} />
          <Route exact path="/policy" element={<Policy />} />
          <Route
            exact
            path="/special/issue_2023Nov"
            element={<Issue2023Nov />}
          />
        </Routes>
      </Router>
      <ImportantNoticeModal
        show={importantNoticeModalShow}
        onHide={() => setImportantNoticeModalShow(false)}
      />
    </>
  );
};

export default App;
