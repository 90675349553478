exports.numberedObjectsInfoList = [
  {
    number: '(719612)',
    name: '',
    provisionalCode: '2019 UW157',
    absoluteMagnitude: 18.56,
    semimajorAxis: 2.6846818,
    eccentricity: 0.0937435,
    inclination: 5.75898,
    detail: [
      {
        userName: 'johnson-cousins_v-bandやねんタイガース',
        HNames: 'H200969, H215870',
      },
      {
        userName: 'さんしろう',
        HNames: 'H214277',
      },
      {
        userName: 'gafaを軽く凌駕する世界的超巨大神企業芳文社様万歳',
        HNames: 'H222335, H223175',
      },
    ],
  },
  {
    number: '(697402)',
    name: 'Ao',
    provisionalCode: '2017 BX232',
    absoluteMagnitude: 17.09,
    semimajorAxis: 3.22934,
    eccentricity: 0.0895423,
    inclination: 8.94156,
    detail: [
      {
        userName: 'arda',
        HNames: 'H275846, H283278',
      },
      {
        userName: 'れお',
        HNames: 'H275846',
      },
      {
        userName: 'ほたる',
        HNames: 'H281115',
      },
    ],
  },
  {
    number: '(718492)',
    name: '',
    provisionalCode: '2017 FZ233',
    absoluteMagnitude: 17.58,
    semimajorAxis: 3.17561,
    eccentricity: 0.0571223,
    inclination: 8.24207,
    detail: [
      {
        userName: 'ほたる',
        HNames: 'H262900',
      },
      {
        userName: 'aika',
        HNames: 'H262900',
      },
      {
        userName: 'arda',
        HNames: 'H262900',
      },
    ],
  },
];
